<template>
  <div class=" container-fluid container-fixed-lg">
    <div class="card card-transparent">
      <div class="card-header d-flex justify-content-between">
        <div class="page-title card-title">
          {{ $t('hotel.info') }}
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-10">
            <div data-pages="card" class="card card-default">
              <div class="card-header">
              </div>
              <div class="card-body">
                <ValidationObserver ref="form_update_basic_info" v-slot="{ passes, valid, validated }">
                  <form novalidate autocomplete="off" @submit.prevent="passes(updateInfo)">
                    <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: $t('common.area name')}"
                               name="area"
                               :label="$t('common.area name')"
                               rules="required"
                               :options-data="areaList"
                               boundary-class="b-b-0"
                               v-model="entry.area_id"/>
                    <AppInput name="name" :label="$t('hotel.hotel_name')" type="string-length:30" rules="required"
                              v-model="entry.name"/>
                    <AppInput name="email" :label="$t('common.mail address')" type="email"
                              :rules="V_EMAIL"
                              v-model="entry.email"/>
                    <AppInput name="phone" :label="$t('common.phone')" type="number-length:11" rules="required|phone"
                              v-model="entry.phone"/>
                    <AppInput name="address" :label="$t('common.address')" type="string" rules="required"
                              v-model="entry.address"/>
                    <AppInput name="fax" :label="$t('common.fax')" type="number-length:10" rules="fax"
                              v-model="entry.fax"/>
                    <button :disabled="!valid && validated" type="submit"
                            :class="['btn inline pull-right', 'btn-complete']">
                      {{ $t('common.save') }}
                    </button>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      areaList: [],
      entry: {},
    }
  },
  mounted() {
    this.$request.get(this.$consts.API.HOTEL.HOTEL_SHOW).then(res => {
      if (!res.hasErrors()) {
        this.entry = res.data.data
      }
    })
    this.$request.get(this.$consts.API.HOTEL.AREA_LIST_SELECT, null, {
      noLoadingEffect: true,
    }).then(res => {
      if (!res.hasErrors()) {
        this.areaList = res.data.data
      }
    })
  },
  methods: {
    async updateInfo() {
      const res = await this.$request.post(this.$consts.API.HOTEL.HOTEL_UPDATE, this.entry)
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg update ok'))
      } else {
        if (res.data?.errors) {
          this.$refs.form_update_basic_info.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
  }
}
</script>
